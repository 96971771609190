import { Badge, IconButton } from "@mui/material";
import React from "react";

import { BellIcon } from "assets";

import {
  NOTIFICATIONS_REFETCH_INTERVAL,
  useNotificationsInfinite,
  usePopoverControl,
} from "hooks";

import { NotificationsPopover } from "./notifications-popover";

import { COLORS } from "../../../consts";

export const Notifications = () => {
  const { close, id, anchorEl, open, isOpen } = usePopoverControl(
    "notifications-popover",
  );
  const { data } = useNotificationsInfinite({
    refetchInterval: NOTIFICATIONS_REFETCH_INTERVAL,
  });

  return (
    <>
      <IconButton
        onClick={open}
        aria-describedby={id}
        sx={{ bgcolor: COLORS.neutralGhWhite, borderRadius: 1 }}
      >
        <Badge
          sx={{ ".MuiBadge-badge": { top: "3px", right: "6px" } }}
          color="error"
          variant="dot"
          invisible={!data || data.pages[0].unreadCount === 0}
        >
          <BellIcon />
        </Badge>
      </IconButton>

      {isOpen && (
        <NotificationsPopover
          id={id}
          anchorEl={anchorEl}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};
