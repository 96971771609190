import MaterialReactTable from "material-react-table";
import React from "react";

import { Loading } from "components";

import { useEmissionFactors } from "hooks";

import { EMISSION_FACTORS_COLUMNS } from "./components";

export const EmissionFactors = ({
  ReferencesData,
}: {
  ReferencesData: any;
}) => {
  const { isLoading } = useEmissionFactors();
  const columns = EMISSION_FACTORS_COLUMNS;
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <MaterialReactTable
          columns={columns}
          muiTableContainerProps={{
            sx: {
              "& thead tr:nth-child(2) th:first-child": {
                paddingLeft: "20px",
              },
              "& tbody tr td:first-child": {
                paddingLeft: "20px",
              },
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              "@media (min-width: 600px)": {
                width: "100%",
              },
              "@media (min-width: 960px)": {
                width: "1100px",
              },
              "@media (min-width: 1280px)": {
                width: "1100px",
              },
              "@media (min-width: 1440px)": {
                width: "1100px",
              },
              "@media (min-width: 1680px)": {
                width: "1300px",
              },
              "@media (min-width: 2400px)": {
                width: "100%",
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: "10px",
              boxShadow: "none",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: "30px",
              "& .MuiBadge-root": {
                display: "none",
              },
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              "& > th": {
                paddingTop: 1,
                paddingBottom: 1,
              },
              height: "45px",
              backgroundColor: "#F8FAFC",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              height: "45px",
              backgroundColor: "white",
            },
          }}
          data={ReferencesData}
          enableColumnResizing
          enableDensityToggle={false}
          initialState={{ density: "compact" }}
          enablePagination={false}
          enableRowVirtualization
        />
      )}
    </div>
  );
};
