import { AppBar, Button, Stack, Toolbar } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useRecoilValue } from "recoil";

import { PowerIcon } from "assets";

import { useSignOut } from "hooks";

import { breadcrumbsState, useUserRole } from "recoils";

import { Notifications } from "./components";

import { ManageUsersDrawer } from "../manage-users-drawer";
import { Label14SemiBold } from "../typography";

export const TopBar: FunctionComponent = () => {
  const breadcrumbs = useRecoilValue(breadcrumbsState);
  const onSignOut = useSignOut();
  const { isAdmin } = useUserRole();
  const [isMangeUsersDrawerOpen, setIsMangeUsersDrawerOpen] = useState(false);

  const handlerFeedback = () => {
    const subject = encodeURIComponent("Submit Feedback");
    const body = encodeURIComponent(`Please provide your feedback here:`);
    const mailtoLink = `mailto:support@esgflo.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <AppBar elevation={0} color="inherit" position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Label14SemiBold>
              {breadcrumbs.map((b) => b.title).join(" > ")}
            </Label14SemiBold>
          </Stack>

          <Stack alignItems="center" direction="row" spacing={2}>
            <Button onClick={handlerFeedback} variant="outlined">
              Submit Feedback
            </Button>
            <Button
              onClick={() => setIsMangeUsersDrawerOpen(true)}
              variant="outlined"
              disabled={!isAdmin}
            >
              Manage users
            </Button>

            <Notifications />

            <Button
              startIcon={<PowerIcon width={20} height={20} />}
              onClick={onSignOut}
              variant="text"
              color="inherit"
            >
              Sign out
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      {isAdmin && (
        <ManageUsersDrawer
          isOpen={isMangeUsersDrawerOpen}
          onClose={() => setIsMangeUsersDrawerOpen(false)}
        />
      )}
    </>
  );
};
