import { buildQueryParams } from "utils";


export const REC_API_PATHS = {
  RECsDOCUMENT: (query?: any) => {
    const baseUrl = "/recdocument";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },

  SITE_REC_DATA: (siteId?: any) => {
    const baseUrl = "/recsdata";

    if (!siteId) {
      return baseUrl;
    }

    return `${baseUrl}/${(siteId)}`;
  },

  RECsDOCUMENT_UPLOADED: "/recdocument/file-uploaded",
  ADD_SITE_REC_DATA: "/recsdata",

  RECsDOCUMENT_DETAILS: (recId?: any) => {
    const baseUrl = "/recdocument";

    if (!recId) {
      return baseUrl;
    }

    return `${baseUrl}/${(recId)}`;
  },

  
  
};
