import { Table as MuiTable, Paper, TableContainer } from "@mui/material";
import React from "react";

import { TableBody } from "./body";
import { TableHead } from "./head";
import { Data, TableProps } from "./types";

export const Table = <TData extends Data>({
  table,
  selectedRows,
  renderSubComponent,
  nonePaddingColumnIds,
}: TableProps<TData>) => {
  const isMissingPage = window.location.pathname === "/documents/missing";
  return (<Paper
    sx={{
      minWidth: isMissingPage ? '100%' : '430px',
      width: {
        xs: isMissingPage ? '100%' : '90%',  
        sm: isMissingPage ? '100%' : '90%',  
        md: isMissingPage ? '100%' : '85%',
        lg: isMissingPage ? '90%' : '70%',
        xl: isMissingPage ? '85%' : '60%',
      },
      '@media (max-width: 1440px)': {
        width: isMissingPage ? '90vw' : '50vw',
      },
      '@media (min-width: 1440px) and (max-width: 1600px)': {
        width: isMissingPage ? '90vw' : '50vw',
      },
      '@media (min-width: 1680px) and (max-width: 2400px)': {
        width: isMissingPage ? '80vw' : '60vw',
      },
      mb: 2,
      borderRadius: "8px 8px 0 0",
      boxShadow: "none",
    }}
  >
    <TableContainer style={{ maxHeight: '900px', overflowY: 'auto' }}>
      <MuiTable>
        <TableHead table={table} />
        <TableBody
          table={table}
          selectedRows={selectedRows}
          renderSubComponent={renderSubComponent}
          nonePaddingColumnIds={nonePaddingColumnIds}
        />
      </MuiTable>
    </TableContainer>
  </Paper>
  )
};
