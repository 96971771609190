import { Box, Button } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";

import { Comment2AlertIcon, TrashIcon } from "assets";

import { Dialog } from "./dialog";

import { FLEX_CENTER_POSITIONING_STYLE } from "../consts";

export const DeleteDialog: FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  onDeleteClick: () => void;
  selectedCount?: number;
  target: ReactNode;
  title: string;
}> = ({ isOpen, onCancel, onDeleteClick, selectedCount, title, target }) => {
  const isPlural = selectedCount && selectedCount > 1;

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      onClose={onCancel}
      actions={
        <Box>
          <Button variant="outlined" color="warning" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="error"
            startIcon={<TrashIcon />}
            onClick={onDeleteClick}
          >
            {target === "Collector" ||
            target === "Site" ||
            target === "Parameter based Estimate" ? (
              <span>De-activate</span>
            ) : (
              <span>Delete</span>
            )}
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          backgroundColor: "error.light",
          p: 2,
          borderRadius: 2,
          mb: 3,
          color: "error.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box component="span" sx={{ ...FLEX_CENTER_POSITIONING_STYLE, mr: 1 }}>
          <Comment2AlertIcon />
        </Box>
        Warning! This action cannot be undone.
      </Box>
      {target === "REC" ? (<Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
          
          The {" "} {target} {isPlural && " "} supporting file will be deleted from the application. Are you sure you want to remove the file?
        </Box>) : (
      <Box>
      {target === "Parameter based Estimate" ? (
        <Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
          <b>Parameter based Estimate</b> will be permanently de-activated from
          the application. The data entries to this point will be kept but you
          will no longer be able to make changes to this estimate. Are you sure
          you want to de-activate this Parameter based Estimate?
        </Box>
      ) : target === "Collector" || target === "Site" ? (
        <Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
          <b>{target === "Collector" ? "Utility provider" : "Site"}</b> will be
          de-activated and can no longer accept new data entries. All associated
          data and files in the platform will remain in the platform.
        </Box>
      ) : (
        <Box sx={{ maxWidth: "450px", marginLeft: "5px" }}>
          {selectedCount} {target}
          {isPlural && "s"} will be permanently deleted from the application.
          The file and all associated data entries will be deleted. Are you sure
          you want to delete these {target}?
        </Box>
      )}
      </Box>)}
    </Dialog>
  );
};
