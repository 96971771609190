/* eslint-disable */
import {
  AppBar,
  Box,
  Button,
  LinearProgress,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import MaterialReactTable, {
  MRT_ColumnFiltersState,
  MRT_RowSelectionState,
} from "material-react-table";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DocumentDTO } from "api";

import {
  Avatar,
  COLORS,
  DatePickerField,
  DocumentsUpload,
  EsgPrimaryLabel,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
  MultiselectControlledField,
  Switch,
  usePagination,
  useSwitchFilter,
  useYearMonthPeriodFilter,
} from "components";
import { EMPTY_CELL_VALUE, useTableExpandedRows } from "components/react-table";

import { useDocuments, useSitesMultiselect } from "hooks";

import { LOCAL_STORAGE_KEYS, TABLE_HEADERS } from "consts";

import { ParsedQueryParams } from "types";

import {
  getDateFormat,
  getDateFormatmonthset,
  getInitials,
  isNullOrUndefined,
  setToLocalStorage,
} from "utils";

import { ADD_DOCUMENT_BILL_PAGE } from "./add-document-bill";
import { Actions } from "./components";

import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "../document-summary-v2/utility-bill/utility-bill-allDataEntries-view/consts";

interface DateObject {
  datefrom: string;
  toDateValue: string;
}

export const DocumentUploadPage = () => {
  // ---- Hooks
  const tableInstanceRef = useRef<any>(null);
  const rowVirtualizerInstanceRef = useRef<any>(null);
  const navigate = useNavigate();
  const currentUrl = useLocation();

  // ---- Const
  const currentDate = new Date();
  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  const fromDateInitial = moment(firstDay)?.format("YYYY-MM-DD");
  const toDateInitial = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
  const storedFromDate = localStorage.getItem("fromDate");
  const storedToDate = localStorage.getItem("toDate");
  const sessionFilterValue = window.sessionStorage.getItem(
    "columnFiltersDocumentUpload",
  );
  const filterValue =
    (sessionFilterValue && JSON.parse(sessionFilterValue)) || [];
  const viewedDocumentID = window.sessionStorage.getItem("viewedDocumentID");
  const maxDate = dayjs(lastDay).utc();
  // ---- States
  const [siteId, setSiteId] = useState<any>();
  const [clearDate, setClearDate] = useState(false);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [columnFilters, setColumnFilters] =
    useState<MRT_ColumnFiltersState>(filterValue);
  const [dateError, setDateError] = useState(false);
  const fromDates =
    siteId !== undefined
      ? null
      : storedFromDate
      ? moment(storedFromDate)
      : fromDateInitial;
  const toDates =
    siteId !== undefined
      ? null
      : storedToDate
      ? moment(storedToDate)
      : toDateInitial;
  const initialState = {
    isYearly: false,
    startDate: moment(fromDates !== null ? fromDates : firstDay).format(
      "YYYY-MM-DD",
    ),
    endDate: moment(toDates !== null ? toDates : lastDay).format("YYYY-MM-DD"),
    fromDate: fromDates,
    toDate: toDates,
    view: "monthly",
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fromDate, toDate } = state;
  const [dateCurrent, setDateCurrent] = useState<DateObject>({
    datefrom: "",
    toDateValue: "",
  });
  const [showBanner, setShowBanner] = useState(false);
  const [dateReload, setDateReload] = useState(true);

  const storedValue = localStorage.getItem("togglebtn");
  const toggleBtn = storedValue ? JSON.parse(storedValue) : true;

  // ---- API's
  const { setInitialExpanded, updateQueryParamsWithInitialExpanded } =
    useTableExpandedRows();

  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_UPLOAD_PAGE_SIZE,
    updateExternalStates: setInitialExpanded,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialExpanded,
  });

  const updateExternalStates = useCallback(() => {
    setInitialPage();
    setInitialExpanded();
  }, [setInitialPage, setInitialExpanded]);

  const getQueryParamsWithExternalChanges = useCallback(
    (queryParams: ParsedQueryParams) =>
      updateQueryParamsWithInitialExpanded(
        updateQueryParamsWithInitialPage(queryParams),
      ),
    [updateQueryParamsWithInitialExpanded, updateQueryParamsWithInitialPage],
  );

  const { period, availableYears } = useYearMonthPeriodFilter({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
  });
  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-01-01`,
  ).utc();

  const {
    siteIds,
    isSitesLoading,
    selectedSites,
    setSelectedSites,
    sitesOptions,
  } = useSitesMultiselect({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
  });

  const { value: isUnmoved, onSwitchChange: onIsUnmovedChange } =
    useSwitchFilter({
      key: "isUnmoved",
      updateExternalStates,
      getQueryParamsWithExternalChanges,
    });

  function reducer(state: any, action: any) {
    let isYearly;
    let period;

    switch (action.type) {
      case "SET_YEARLY":
        ({ isYearly, period } = action.payload);
        return {
          ...state,
          isYearly,
          view: isYearly ? "yearly" : "monthly",
          startDate: isYearly
            ? period?.startOf("year").format("YYYY-MM-DD")
            : `${period.format("YYYY-MM")}-01`,
          endDate: isYearly
            ? period?.endOf("year").format("YYYY-MM-DD")
            : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
        };
      case "SET_FROM_DATE":
        return {
          ...state,
          fromDate: action.payload.value,
          view: "daterange",
          startDate: action.payload.value,
        };
      case "SET_TO_DATE":
        return {
          ...state,
          toDate: action.payload.value,
          view: "daterange",
          endDate: action.payload.value,
        };
      case "SET_START_AND_END_DATES":
        return {
          ...state,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        };

      case "CLEAR_DATES":
        return {
          ...state,
          fromDate: null,
          toDate: null,
          startDate: moment(firstDay).format("YYYY-MM-DD"),
          endDate: moment(lastDay).format("YYYY-MM-DD"),
          // isYearly: true,
          view: "monthly",
        };
      default:
        return state;
    }
  }

  useEffect(() => {
    window.sessionStorage.setItem(
      "columnFiltersDocumentUpload",
      JSON.stringify(columnFilters),
    );
    if (columnFilters?.length === 0) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [columnFilters]);

  useEffect(() => {
    const firstDate = new Date();
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    const datefrom = moment(formattedFirstDate).format("YYYY-MM-DD");

    const storedfromValue = localStorage.getItem("fromDate");
    const storedtoValue = localStorage.getItem("toDate");

    if (!storedfromValue) {
      localStorage.setItem(
        "fromDate",
        moment(formattedFirstDate)?.format("YYYY-MM-DD"),
      );
    }

    const lastDate = new Date();
    const currentYear = lastDate.getFullYear();
    const currentMonth = lastDate.getMonth();
    const storedYear = storedtoValue ? moment(storedtoValue).year() : null;
    const storedMonth = storedtoValue ? moment(storedtoValue).month() : null;
    const currentDates = lastDate.getDate();
    const storedDates = storedtoValue ? moment(storedtoValue).date() : null;
    // const lastDateOfMonth = new Date(lastDate?.getFullYear(), lastDate?.getMonth() + 1, 0);
    // const formattedLastDate = lastDateOfMonth
    const toDateValue = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
    if (
      !storedtoValue ||
      (currentYear === storedYear &&
        currentMonth === storedMonth &&
        currentDates !== storedDates)
    ) {
      localStorage.setItem(
        "toDate",
        moment(currentDate).add(1, "day").format("YYYY-MM-DD"),
      );
    }
    ///end code //
    setDateCurrent({ datefrom, toDateValue });
  }, []);

  useEffect(() => {
    if (
      tableRows?.length > 0 &&
      tableInstanceRef?.current &&
      viewedDocumentID
    ) {
      const selectedIndex = tableRows.findIndex(
        (row) => row?.id === viewedDocumentID,
      );
      if (selectedIndex !== -1) {
        const elementToScroll = rowVirtualizerInstanceRef?.current;
        const targetIndex = selectedIndex + 5;

        const duration = 1500;
        const startTime = performance.now();

        const scrollNextFrame = (timestamp: any) => {
          const currentTime = timestamp - startTime;
          const progress = currentTime / duration;
          const currentIndex = Math.round(selectedIndex + 7 * progress);

          if (currentIndex < targetIndex) {
            elementToScroll.scrollToIndex(currentIndex);
            requestAnimationFrame(scrollNextFrame);
          } else {
            elementToScroll.scrollToIndex(targetIndex);
          }
        };

        requestAnimationFrame(scrollNextFrame);
      }
    }
  }, [viewedDocumentID]);

  const determineAlgorithmName = (row: any) => {
    const { service, source, routing } = row;

    if (service === "default" && source === "idpv1" && routing === "fallback") {
      return "IDP Fallback";
    } else if (
      service === "default" &&
      source === "idpv1" &&
      routing === "default"
    ) {
      return "IDP A/B Testing";
    } else if (service === "default" && source === "abbyy") {
      return "Current";
    } else {
      return "-"; // Default value if none of the conditions are met
    }
  };

  const { data, isLoading1 } = useDocuments({
    uploadDateFrom: !dateError
      ? fromDates !== null
        ? moment(fromDates).format("YYYY-MM-DD")
        : siteId !== undefined
        ? null
        : fromDate
      : dateCurrent?.datefrom,
    uploadDateTo: !dateError
      ? toDates !== null
        ? moment(toDates).format("YYYY-MM-DD")
        : siteId !== undefined
        ? null
        : toDate
      : dateCurrent?.toDateValue,
    siteIds: siteId !== undefined ? siteId : siteIds,
    isUnmoved,
  });

  const tableRows = data !== null ? data?.documents || [] : [];

  const customFilter = (rows: any, filterValue: string) => {
    return rows.filter(
      (row: {
        original: { uploader: { firstName: string; lastName: string } };
      }) => {
        const uploaderName =
          row?.original?.uploader?.firstName +
          " " +
          row?.original?.uploader?.lastName;

        // Customize the comparison logic based on your requirements
        return uploaderName.toLowerCase() === filterValue.toLowerCase();
      },
    );
  };

  // Page Handler Functions
  const handleRowSelection = () => {
    setRowSelection({});
  };

  const handleFromDateChange = (date: any) => {
    const firstDate = new Date(date);
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;

    localStorage.setItem(
      "fromDate",
      moment(formattedFirstDate).format("YYYY-MM-DD"),
    );
    const formDateValue = moment(formattedFirstDate).format("YYYY-MM-DD");
    const fromDateExists = localStorage.getItem("fromDate");
    let formattedtest = fromDateExists !== null ? fromDateExists : fromDate;
    const toDateExists = localStorage.getItem("toDate");
    if (formDateValue === formattedtest) {
      formattedtest = toDateExists;
    }
    if (fromDate && formDateValue > formattedtest) {
      setDateError(true);
    } else {
      dispatch({
        type: "SET_FROM_DATE",
        payload: {
          value: moment(formattedFirstDate).format("YYYY-MM-DD"),
          period,
        },
      });
      setDateError(false);
    }
  };

  const handleToDateChange = (date: any) => {
    const currentDate = new Date();
    const currentMonth = currentDate?.getMonth();
    const currentYear = currentDate?.getFullYear();

    const selectedDate = new Date(date);
    const selectedMonth = selectedDate?.getMonth();
    const selectedYear = selectedDate?.getFullYear();
    let toDateValue;
    if (currentMonth === selectedMonth && currentYear === selectedYear) {
      toDateValue = moment(currentDate)?.format("YYYY-MM-DD");
    } else {
      const lastDateOfMonth = new Date(
        selectedDate?.getFullYear(),
        selectedDate?.getMonth() + 1,
        0,
      );
      const formattedLastDate = lastDateOfMonth;
      toDateValue = moment(formattedLastDate)?.format("YYYY-MM-DD");
    }
    const fromDatevalidation = moment(fromDates)?.format("YYYY-MM-DD");

    localStorage.setItem("toDate", toDateValue);

    const datevaluecheck =
      fromDate !== null
        ? moment(fromDate)?.format("YYYY-MM-DD")
        : moment(fromDatevalidation)?.format("YYYY-MM-DD");

    if (toDateValue < datevaluecheck) {
      setDateError(true);
    } else {
      dispatch({
        type: "SET_TO_DATE",
        payload: { value: toDateValue, period },
      });
      setDateError(false);
    }
  };

  useEffect(() => {
    if (dateReload === false) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateReload]);

  useEffect(() => {
    if (!toggleBtn) {
      const fromDateExists = localStorage.getItem("fromDate") !== null;
      const toDateExists = localStorage.getItem("toDate") !== null;
      if (fromDateExists) {
        localStorage.removeItem("fromDate");
        setDateReload(false);
      }
      if (toDateExists) {
        localStorage.removeItem("toDate");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBtn]);

  const handleResetDate = () => {
    const firstDate = new Date();
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    localStorage.setItem(
      "fromDate",
      moment(formattedFirstDate)?.format("YYYY-MM-DD"),
    );

    const lastDate = new Date();
    const toDateValue = moment(lastDate)?.format("YYYY-MM-DD");
    localStorage.setItem("toDate", toDateValue);
  };

  const handleClearDates = () => {
    dispatch({ type: "CLEAR_DATES", payload: null });
    setDateError(false);
    setClearDate(true);
    setSelectedSites([]);

    const fromDateExists = localStorage.getItem("fromDate") !== null;
    const toDateExists = localStorage.getItem("toDate") !== null;
    if (fromDateExists) {
      handleResetDate();
    }
    if (toDateExists) {
    }
    setTimeout(() => {
      setClearDate(false);
    }, 100);
    window.location.reload();
  };

  const onResetAll = () => {
    window.sessionStorage.setItem(
      "columnFiltersDocumentUpload",
      JSON.stringify([]),
    );
    setColumnFilters([]);
    setSiteId(undefined);
  };

  const setDateValue = (dateValue1: any, dateValue2: any) => {
    if (dateValue1 !== null) {
      return dayjs(dateValue1);
    }
    return dateValue2;
  };

  // ---- Table Column
  const columns: any = [
    {
      header: "File name",
      Header: "File name",
      accessorKey: "fileName",
      Cell: ({ cell, row }: any) => {
        const value = cell?.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 140,
    },
    {
      header: "Site",
      Header: <Box sx={{ paddingLeft: "12px" }}> Site</Box>,
      accessorKey: "site",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                paddingLeft: "12px",
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 120,
    },
    {
      header: "Utility Type",
      Header: <Box sx={{ paddingLeft: "10px" }}>Utility Type</Box>,
      accessorKey: "utilityType",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as string;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
          <Tooltip title={value}>
            <Box
              sx={{
                paddingLeft: "12px",
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 140,
    },
    {
      accessorFn: (row: any) =>
        `${row?.uploader?.firstName} ${row?.uploader?.lastName}`,
      filterMethod: customFilter,
      header: TABLE_HEADERS.uploader,
      Header: <Box sx={{ paddingLeft: "14px" }}>Uploader</Box>,
      id: "uploader",
      Cell: ({ cell, row }: any) => {
        const value = cell?.getValue();
        if (!value) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Tooltip title={row?.original?.uploader?.email}>
            <Box sx={{ paddingLeft: "14px" }}>
              <Avatar
                sx={{
                  width: "34px",
                  height: "34px",
                }}
              >
                {getInitials(value)}
              </Avatar>
            </Box>
          </Tooltip>
        );
      },
      size: 150,
    },
    {
      header: "Uploaded at",
      Header: <Box sx={{ paddingLeft: "0px" }}>Uploaded at</Box>,
      accessorKey: "uploadDate",
      Cell: ({ cell }: any) => {
        const date: string = cell?.getValue() as string;
        const time = dayjs(date)?.utc()?.local()?.format("HH:mm");
        const value = dayjs(date)?.utc()?.local()?.format(getDateFormat());
        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return <Box>{`${value}, ${time}`}</Box>;
      },
      size: 160,
    },
    {
      header: "Pages",
      Header: <Box>Pages</Box>,
      accessorKey: "pages",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as DocumentDTO["pages"];

        return (
          <Box sx={{ textAlign: "center", paddingLeft: "5px" }}>
            {value || EMPTY_CELL_VALUE}
          </Box>
        );
      },
      size: 100,
    },
    {
      header: "Data entries",
      Header: <Box>Data Entries</Box>,
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as DocumentDTO["pages"];

        return (
          <Box sx={{ textAlign: "center", paddingLeft: "5px" }}>
            {value || EMPTY_CELL_VALUE}
          </Box>
        );
      },
      accessorFn: (originalRow: any) =>
        originalRow?.bills?.length === null ||
        originalRow?.bills?.length === undefined
          ? "-"
          : originalRow?.bills?.length,
      size: 140,
    },
    {
      header: "AI Extraction",
      Header: <Box>AI Extraction</Box>,
      accessorFn: (originalRow: any) => originalRow?.bills,
      filterFn: (row: any, id: any, filterValue: any) => {
        const userInput = filterValue;

        const value = row?.original?.bills;

        const totalFields: number = value?.length * 5;
        const filled = value?.reduce((acc: any, bill: any) => {
          if (bill?.ocrInputFields !== null) {
            return acc + bill?.ocrInputFields;
          }
          return acc;
        }, 0);

        let progress: number = (filled / totalFields) * 100;

        const finalProcessValue = Math.round(progress);

        const compareData = parseInt(userInput) === finalProcessValue;

        if (compareData) {
          return true;
        }
        return false;
      },
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as Array<any>;

        const totalFields: number = value?.length * 5;
        const filled = value?.reduce((acc, bill) => {
          if (bill?.ocrInputFields !== null) {
            return acc + bill?.ocrInputFields;
          }
          return acc;
        }, 0);
        let progress: number = (filled / totalFields) * 100;

        if (isNaN(progress)) {
          progress = 0;
        }

        function getFileExtension(filename: any) {
          const parts = filename?.split(".");
          if (parts?.length > 1) {
            return parts?.pop()?.toLowerCase();
          }
          return "";
        }

        const fileExtension = getFileExtension(
          cell?.row?.original?.fileName?.toLowerCase(),
        );

        return (
          <Box
            sx={{
              width: "100px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {(cell?.row?.original?.uploadDate < "2023-09-12" &&
              progress === 0) ||
            fileExtension !== "pdf" ? (
              <>
                <LinearProgress
                  sx={{
                    minWidth: "90px",
                    height: "8px",
                    borderRadius: "5px",
                    border: "2px solid #DCDCDC",
                    backgroundColor: "#f3f3f3",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#DCDCDC",
                    },
                  }}
                  variant="determinate"
                  value={100}
                />
                <p style={{ paddingLeft: "4px", fontSize: "12px" }}>NA</p>
              </>
            ) : (
              <>
                <LinearProgress
                  sx={{
                    minWidth: "90px",
                    height: "8px",
                    borderRadius: "5px",
                    border: `1px solid ${COLORS.Mainblue}`,
                    backgroundColor: "#f3f3f3",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: COLORS.Mainblue,
                    },
                  }}
                  variant="determinate"
                  value={progress}
                />
                <p
                  style={{ paddingLeft: "4px", fontSize: "12px" }}
                >{`${Math?.round(progress)}%`}</p>
              </>
            )}
          </Box>
        );
      },
      size: 150,
    },
    {
      header: "AI Algorithm",
      Header: <Box sx={{ paddingLeft: "12px" }}> AI Algorithm</Box>,
      accessorKey: "algorithm",
      Cell: ({ cell }: any) => {
        const row = cell.row.original;
        const value = determineAlgorithmName(row);

        return (
          <Tooltip title={value}>
            <Box
              sx={{
                paddingLeft: "12px",
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 200,
      filterFn: (row: any, id: any, filterValue: any) => {
        const algorithmName = determineAlgorithmName(row.original);
        return algorithmName.toLowerCase().includes(filterValue.toLowerCase());
      },
    },
    {
      header: "Data entries status",
      Header: <Box>Data Entries Status</Box>,
      accessorKey: "bills",
      filterFn: (row: any, id: any, filterValue: any) => {
        const userInput = filterValue;
        const bills = row?.original?.bills;
        const getStatusPriority = (status: string) => {
          if (status === "INCOMPLETE_DATA") {
            return 0;
          } else if (status === "VERIFICATION_FAILED") {
            return 1;
          } else if (status === "CLEANED") {
            return 2;
          }
          return 3;
        };

        const getStatusLabel = (statusKey: any) => {
          if (statusKey === "INCOMPLETE_DATA") {
            return "Incomplete";
          } else if (statusKey === "VERIFICATION_FAILED") {
            return "Needs Review";
          } else if (statusKey === "CLEANED") {
            return "Ready";
          }
          return "-";
        };

        const highestPriorityBill = bills?.reduce((prev: any, current: any) => {
          if (
            getStatusPriority(current?.status) < getStatusPriority(prev?.status)
          ) {
            return current;
          }
          return prev;
        }, bills[0]);

        const finalProcessValue = getStatusLabel(
          highestPriorityBill && highestPriorityBill?.status,
        );

        const compareData = finalProcessValue
          .toLowerCase()
          .includes(userInput.toLowerCase());

        if (compareData) {
          return true;
        }
        return false;
      },
      Cell: ({ cell, row }: any) => {
        const bills = cell?.getValue() as Array<any>;
        const getStatusPriority = (status: string) => {
          if (status === "INCOMPLETE_DATA") {
            return 0;
          } else if (status === "VERIFICATION_FAILED") {
            return 1;
          } else if (status === "CLEANED") {
            return 2;
          }
          return 3;
        };

        const getMissingProperties = (bill: any) => {
          const missingProperties = [];
          if (bill?.status === "INCOMPLETE_DATA" && bill?.errorMessage) {
            if (!bill?.siteName || bill?.siteName === "Generic") {
              missingProperties.push("Site");
            }

            if (!bill?.consumptionPeriodFrom) {
              missingProperties.push("Start Date");
            }

            if (!bill?.consumptionPeriodTo) {
              missingProperties.push("End Date");
            }

            if (bill?.measuredUsageValue === null) {
              missingProperties.push("Usage");
            }

            if (bill?.amount === null) {
              missingProperties.push("Cost");
            }
          }

          return missingProperties;
        };

        const getStatusLabel = (statusKey: any) => {
          if (statusKey === "INCOMPLETE_DATA") {
            return "Incomplete";
          } else if (statusKey === "VERIFICATION_FAILED") {
            return "Needs Review";
          } else if (statusKey === "CLEANED") {
            return "Ready";
          }
          return "-";
        };

        let totalMissingFields = 0;
        const missingFieldsList: string[] = [];
        bills?.forEach((bill) => {
          if (bill?.status === "INCOMPLETE_DATA") {
            const missingProperties = getMissingProperties(bill);
            totalMissingFields += missingProperties?.length;
            missingFieldsList.push(...missingProperties);
          }
        });

        const uniqueMissingFieldsList = Array?.from(new Set(missingFieldsList));

        const highestPriorityBill = bills?.reduce((prev, current) => {
          if (
            getStatusPriority(current?.status) < getStatusPriority(prev?.status)
          ) {
            return current;
          }
          return prev;
        }, bills[0]);

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "4px",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            {highestPriorityBill && (
              <Tooltip
                placement="top"
                title={
                  getStatusPriority(highestPriorityBill?.status) === 0 ? (
                    totalMissingFields > 0 ? (
                      <ul>
                        {uniqueMissingFieldsList?.map((property: string) => (
                          <li key={property} style={{ marginLeft: "-26px" }}>
                            {property}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "Incomplete"
                    )
                  ) : getStatusPriority(highestPriorityBill?.status) === 1 ? (
                    <ul>
                      {highestPriorityBill?.errorMessage !== null
                        ? highestPriorityBill?.errorMessage
                            .split("\n")
                            .map((point: any, index: any) => (
                              <div
                                key={`high-Priority-Bill-tooltip-${row?.index}`}
                              >
                                <li key={index} style={{ marginLeft: "-26px" }}>
                                  {point}
                                </li>
                              </div>
                            ))
                        : ""}
                    </ul>
                  ) : getStatusPriority(highestPriorityBill?.status) === 2 ? (
                    "Associated emissions have been included"
                  ) : (
                    ""
                  )
                }
              >
                <span style={{ position: "relative" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill={
                      getStatusPriority(highestPriorityBill?.status) === 0
                        ? "#D86262"
                        : getStatusPriority(highestPriorityBill?.status) === 1
                        ? "#FDC501"
                        : getStatusPriority(highestPriorityBill?.status) === 2
                        ? "#2E8B21"
                        : "currentColor"
                    }
                    style={{ marginRight: "9px", marginBottom: "2px" }}
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" />
                  </svg>
                  {getStatusLabel(highestPriorityBill?.status)}
                  {totalMissingFields > 0 && (
                    <span>({totalMissingFields})</span>
                  )}
                </span>
              </Tooltip>
            )}
          </Box>
        );
      },
      size: 220,
    },
  ];

  localStorage.removeItem("setid");

  function getCircularReplacer() {
    const seen = new WeakSet();
    return (key: any, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  return (
    <MarginWrapper>
      {/* <Box sx={FILTERS_AND_ACTIONS_WRAPPER}> */}
      <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
        <Box
          sx={{
            width: dateError ? "400px" : "400px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Stack direction="row" spacing={2}>
            <DatePickerField
              label="From Month"
              views={["year", "month"]}
              openTo="month"
              minDate={minDate}
              maxDate={maxDate}
              format={getDateFormatmonthset()}
              disableHighlightToday
              disableFuture
              onChange={() => {}}
              onAccept={handleFromDateChange}
              value={setDateValue(fromDates, fromDate)}
              clearDate={clearDate}
              disabled={!toggleBtn}
              placeholdervalue="MM, YYYY"
              filterAll={siteId !== undefined ? true : false}
            />
            <DatePickerField
              label="To Month"
              views={["year", "month"]}
              openTo="month"
              minDate={minDate}
              maxDate={maxDate}
              format={getDateFormatmonthset()}
              disableHighlightToday
              // disableFuture
              onChange={() => {}}
              onAccept={handleToDateChange}
              value={setDateValue(toDates, toDate)}
              clearDate={clearDate}
              disabled={!toggleBtn}
              placeholdervalue="MM, YYYY"
              filterAll={siteId !== undefined ? true : false}
            />
          </Stack>
        </Box>
        <Box sx={{ minWidth: "220px", width: "fit-content" }}>
          <MultiselectControlledField
            label="Sites"
            disabled={isSitesLoading}
            selectedValues={selectedSites}
            setSelectedValues={setSelectedSites}
            options={sitesOptions}
            isAllSelectedOnEmptySelection
          />
        </Box>
        <Box
          sx={{
            pt: 2,
          }}
        >
          <DocumentsUpload isScope3={false} isLoading1={isLoading1} />
        </Box>
        <Button
          sx={{
            width: "120px",
            paddingTop: "10px",
            border: "1px solid #000",
            marginTop: "28px",
          }}
          onClick={handleClearDates}
        >
          Clear Filters
        </Button>
      </Box>

      {dateError && (
        <Box sx={[FLEX_ALIGN_CENTER_GAP_2, { mt: 1 }]}>
          <EsgPrimaryLabel sx={{ color: "red" }}>
            Invalid date selection. Please choose a month after the From Month.
          </EsgPrimaryLabel>
        </Box>
      )}
      <Box sx={[FLEX_ALIGN_CENTER_GAP_2, { my: 3 }]}>
        <Button
          variant="outlined"
          onClick={onResetAll}
          disabled={!columnFilters?.length && siteId === undefined}
        >
          Reset all filters
        </Button>
        <Actions
          rowSelection={rowSelection}
          setrowSelection={handleRowSelection}
        />
        <Switch
          isChecked={isUnmoved}
          label="Show only unmoved"
          onChange={onIsUnmovedChange}
        />
      </Box>
      {/* </Box> */}

      {isLoading1 && tableRows?.length <= 0 ? (
        <Loading />
      ) : (
        <>
          {data?.documents.length === 0 && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                mt: 3,
                backgroundColor: COLORS.Romance,
                color: "#FFFFFF",
                padding: "2px",
              }}
            >
              No bills have been uploaded for this month yet. Please check the
              previous month's bills or upload more bills.
            </Typography>
          )}
          <MaterialReactTable
            tableInstanceRef={tableInstanceRef}
            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
            data={tableRows}
            columns={columns}
            enableRowSelection
            enableStickyHeader
            enableRowVirtualization
            enablePagination={false}
            onRowSelectionChange={setRowSelection}
            onColumnFiltersChange={setColumnFilters}
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            state={{ rowSelection, columnFilters }}
            getRowId={(row: any) => row.id}
            muiTableContainerProps={{
              sx: {
                "& thead tr:first-child th:first-child": {
                  paddingLeft: "20px",
                },
                "& tbody tr td:first-child": {
                  paddingLeft: "20px",
                },
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "45px",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={(row) => ({
              onClick: () => {
                setToLocalStorage("dataEntries", JSON.stringify(row?.row));
                const handleClick = async () => {
                  if (row?.row?.original?.bills !== null) {
                    if (row?.row?.original?.bills?.length > 0) {
                      window.sessionStorage.setItem(
                        "summaryDocumentID",
                        row?.row?.original?.bills[0]?.id,
                      );
                      navigate(
                        UTILITY_BILL_ALL_DATAENTRIES_VIEW.ABSOLUTE_PATH(
                          row?.row?.original?.bills[0]?.id,
                        ),
                      );
                    } else {
                      window.sessionStorage.setItem(
                        "summaryDocumentID",
                        row?.row?.original?.id as any,
                      );
                      navigate(
                        ADD_DOCUMENT_BILL_PAGE.ABSOLUTE_PATH(
                          row?.row?.original?.id as string,
                        ),
                      );
                    }
                  }

                  window.sessionStorage.setItem(
                    "viewedDocumentID",
                    row?.row?.original?.id as string,
                  );
                  setToLocalStorage("type", "Upload");
                  setToLocalStorage(
                    "Upload_URL",
                    currentUrl?.pathname + currentUrl?.search,
                  );
                  setToLocalStorage(
                    "dataEntries",
                    JSON.stringify(row, getCircularReplacer()),
                  );
                  setToLocalStorage(
                    "algorithm",
                    determineAlgorithmName(row?.row?.original),
                  );
                };
                handleClick();
              },
              sx: {
                cursor: "pointer",
                backgroundColor:
                  String(row?.row?.original?.id) === String(viewedDocumentID)
                    ? "#DCDCDC"
                    : "white",
              },
            })}
            muiTableBodyCellProps={(row) => ({
              sx: {
                opacity:
                  row.row.original.moved === true
                    ? 1
                    : row.row.original.status === "PARSED" ||
                      row.row.original.status === "NO_RESPONSE"
                    ? 1
                    : 0.5,
                ":disabled":
                  row.row.original.moved === true
                    ? false
                    : row.row.original.status === "PARSED" ||
                      row.row.original.status === "NO_RESPONSE"
                    ? false
                    : true,
              },
            })}
            renderTopToolbarCustomActions={({ table }) => (
              <Box>
                <Box>
                  {siteIds?.length !== 0 || showBanner ? (
                    <AppBar
                      position="static"
                      sx={{
                        backgroundColor: COLORS.Romance,
                        height: "23px",
                        top: "auto",
                        bottom: 0,
                        textAlign: "center",
                        my: "1rem",
                        width: "140%",
                      }}
                    >
                      <Toolbar
                        sx={{
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography
                          sx={{ mb: 5, paddingLeft: 50, paddingRight: 70 }}
                          align="center"
                        >
                          Filter is only being applied to the current date range
                          selected
                        </Typography>
                      </Toolbar>
                    </AppBar>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            )}
          />
        </>
      )}
    </MarginWrapper>
  );
};
