import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";

import {
  SiteByIdDTO,
} from "api";

import { COLORS, InputFieldWrapper, TYPOGRAPHY_COMMON_STYLES } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { checkNullValue, getDateFormatset } from "utils";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addRecDataBySite, editRecDataBySite } from "api/rec";
import moment from "moment";

interface CollectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  columns?: any;
  site?: SiteByIdDTO;
  editREC?: any;
}

export const RecModal: FC<CollectorModalProps> = ({
  isOpen,
  onClose,
  columns,
  allowedValues,
  site,
  editREC,
}) => {

  // ---- Hook ----
  const notify = useNotify();
  const queryClient = useQueryClient();

  const initialFormValues =
    columns &&
    columns.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any);

  const { control, getValues, setValue } = useForm<any>({
    mode: "onChange",
    defaultValues: initialFormValues,
  });


  // ---- State ----
  const [values, setValues] = useState<any>(initialFormValues);

  // ---- Const ----
  const {
    countries,
    utilityTypes = [],
  } = allowedValues || {};
  const CountriesAscending = countries?.sort() || [];

  // ---- API ----
  const { mutateAsync: addRecDocument } = useMutation(addRecDataBySite);
  const { mutateAsync: editRecDocument } = useMutation(editRecDataBySite);

  // ---- Use Effect ----
  useEffect(() => {
    if (editREC) {
      const updatedValues = columns.reduce((acc: any, column: any) => {
        acc[column.accessorKey ?? ""] = editREC[column.accessorKey] || "";
        return acc;
      }, {} as any);
      setValues(updatedValues);
    } else {
      setValues(initialFormValues);
    }
    // eslint-disable-next-line
  }, [columns, editREC]);


  useEffect(() => {
    if (editREC) {
      setValue('utilityTypeName', editREC?.utilityTypeName);
      setValue('country', editREC?.country);
    }
  }, [editREC, setValue]);


  // ---- Page Function ----
  const handleClose = () => {
    setValues(initialFormValues);
    onClose();
  };

  const handleAddRec = async () => {

    const createRecRequestData: any = {
      utilityTypeId: utilityTypes.find(
        (ut: any) =>
          ut.name.toLowerCase() === getValues().utilityTypeName.toLowerCase(),
      )?.id || 0,
      siteId: Number(site),
      utilityProvider: values.utilityProvider,
      country: getValues()?.country,
      renewableEnergy: values.renewableEnergy,
      startDate: checkNullValue(values.startDate),
      endDate: checkNullValue(values.endDate),
    };

    // Proceed with creating collector if all checks pass
    await addRecDocument(createRecRequestData, {
      onError: (error: any) => {
        if (error?.response?.data) {
          notify.error(error?.response?.data);
        } else {
          notify.error(`Some error has happened while adding Rec!`);
        }
      },
      onSuccess: () => {
        notify.success(`REC successfully added!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
      },
    });
  };

  const handleEditREC = async () => {
    const createRecRequestData: any = {
      utilityType: getValues()?.utilityTypeName,
      utilityProvider: values.utilityProvider,
      country: getValues()?.country,
      renewableEnergy: values.renewableEnergy,
      startDate: checkNullValue(moment(values.startDate).format("YYYY-MM-DDT00:00:00[Z]")),
      endDate: checkNullValue(moment(values.endDate).format("YYYY-MM-DDT00:00:00[Z]")),
    };

    // Proceed with creating collector if all checks pass
    await editRecDocument({ recId: editREC?.recId, payload: createRecRequestData }, {
      onError: () => {
        notify.error(`Some error has happened while editing Rec!`);
      },
      onSuccess: () => {
        notify.success(`REC successfully edited!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
      },
    });
  };

  const customFieldsOrder = [
    "utilityProvider",
    "utilityTypeName",
    "country",
    "renewableEnergy",
    "startDate",
    "endDate",
  ];

  const sortedColumns = customFieldsOrder?.map((key) =>
    columns.find((column: any) => column.accessorKey === key),
  );


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        {editREC ? "Rec Details" : " New Rec Details"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {sortedColumns && (sortedColumns?.map((column: any) => (
              <Grid item xs={8} sm={6} key={column.header}>
                {column.accessorKey === "utilityProvider" && (
                  <InputFieldWrapper label={column.header}>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        fieldState: { error },
                      }) => (
                        <TextField
                          id={column.accessorKey}
                          size="small"
                          error={!!error}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={values[column.accessorKey] || ""}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "utilityTypeName" && (
                  <InputFieldWrapper label={column.header}>
                    <Controller
                      name="utilityTypeName"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="utilityType-simple-select-label"
                          id="utilityType-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {utilityTypes.map((t: any) => (
                            <MenuItem
                              sx={{
                                fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                fontSize: 14,
                                lineHeight: "18px",
                                color: '#90959E',
                                '&:hover': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                              }} key={t?.id} value={t?.name}>
                              {t?.name}</MenuItem>
                          ))}
                        </Select>
                      )
                      }
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "country" && (
                  <InputFieldWrapper label="Country">
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="country-simple-select-label"
                          id="country-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {CountriesAscending.map((t: any) => (
                            <MenuItem
                              sx={{
                                fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                fontSize: 14,
                                lineHeight: "18px",
                                color: '#90959E',
                                '&:hover': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                              }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                        </Select>
                      )
                      }
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "renewableEnergy" && (
                  <InputFieldWrapper label={column.header}>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        fieldState: { error },
                      }) => (
                        <TextField
                          id={column.accessorKey}
                          size="small"
                          error={!!error}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={values[column.accessorKey] || ""}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "startDate" && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  >
                    <InputFieldWrapper label={column.header}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              width: "100%",
                            }
                          },
                        }}
                        value={
                          values[column.accessorKey]
                            ? dayjs(
                              values[column.accessorKey],
                            ).utc()
                            : null
                        }
                        onChange={(date) =>
                          setValues({
                            ...values,
                            [column.accessorKey]: dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                          })
                        }
                        format={getDateFormatset()}
                      />
                    </InputFieldWrapper>
                  </LocalizationProvider>
                )}
                {column.accessorKey === "endDate" && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  >
                    <InputFieldWrapper label={column.header}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              width: "100%",
                            }
                          },
                        }}
                        value={
                          values[column.accessorKey]
                            ? dayjs(
                              values[column.accessorKey],
                            ).utc()
                            : null
                        }
                        onChange={(date) =>
                          setValues({
                            ...values,
                            [column.accessorKey]: dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                          })
                        }
                        format={getDateFormatset()}
                      />
                    </InputFieldWrapper>
                  </LocalizationProvider>
                )}
              </Grid>
            )))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
          ml={2}
        >
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() =>
                editREC
                  ? handleEditREC()
                  : handleAddRec()
              }
              variant="contained"
              color="primary"
            >
              {editREC ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
